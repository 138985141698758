import { ComputeInstanceSize, type InstanceType } from '../compute';

/**
 * WNext per-app pricing table.
 *
 * TODO: should this go in a backend API?
 */
export const WNextPricing = {
  default: {
    [ComputeInstanceSize.Micro]: 6, //    4,  4
    [ComputeInstanceSize.Small]: 10, //   8,  6
    [ComputeInstanceSize.Medium]: 20, // 15, 12
    [ComputeInstanceSize.Large]: 75, //  55, 48
    [ComputeInstanceSize.XLarge]: 130, // 100, 96
    [ComputeInstanceSize.XLargeAI]: 1900, // 1693, 1466
  },
  baserow: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 6,
    [ComputeInstanceSize.Medium]: 15,
    [ComputeInstanceSize.Large]: 48,
  },
  'code-go': {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 6,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  gitea: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 9,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  gitlab: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 6,
    [ComputeInstanceSize.Medium]: 99,
    [ComputeInstanceSize.Large]: 48,
  },
  gogs: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 9,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  helloworld: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 6,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  jupyterlab: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 6,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  'jupyter-notebook': {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 6,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  kanboard: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 15,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  matomo: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 20,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  'matrix-element': {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 20,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  mastodon: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 12,
    [ComputeInstanceSize.Medium]: 30,
    [ComputeInstanceSize.Large]: 48,
  },
  'btc-lnd': {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 30,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 60,
  },
  sphinx: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 30,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 60,
  },
  'sphinx-swarm': {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 9,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  nextcloud: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 6,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  penpot: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 20,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  pixelfed: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 20,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  formbricks: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 15,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  mediawiki: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 9,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  nostream: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 9,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  'nostr-rs-relay': {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 9,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
  aperture: {
    [ComputeInstanceSize.Micro]: 4,
    [ComputeInstanceSize.Small]: 9,
    [ComputeInstanceSize.Medium]: 12,
    [ComputeInstanceSize.Large]: 48,
  },
};

/**
 * Get the WNext pricing for a given app
 *
 * @param appID
 * @param size
 * @returns
 */
export function getWNextPricing(appID: string, size?: ComputeInstanceSize): InstanceType {
  const s = size || ComputeInstanceSize.Small;
  const price = WNextPricing[appID]?.[s] || WNextPricing.default[s];
  return [s, price];
}
