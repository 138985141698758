/* eslint-disable @typescript-eslint/no-unused-vars */

import { createRandomKey } from '../crypto';
import { FetchWrapper } from '../http';
import { type Params, toQS } from '../http/qs';
import { type ComputeProviderConfig, ComputeProviderName, type Instance } from '../types';
import { sleep } from '../util/sleep';

import type { ICreateDropletApiRequest, IDroplet, IDropletResponse } from './types/digitalocean';
import {
  ComputeInstanceImage,
  ComputeInstanceRegion,
  ComputeInstanceSize,
  type ComputeProvider,
  type CreateInstanceRequest,
  type DestroyInstanceRequest,
  type DestroyInstanceResponse,
  type GetInstanceRequest,
  type InstanceType,
  type InstanceTypeMap,
  type ListInstancesRequest,
  type OAuthConfig,
  type OAuthSupport,
  type OAuthToken,
  type UpdateInstanceRequest,
} from './index';

const DO_OAUTH_URL = 'https://cloud.digitalocean.com/v1/oauth';

const DO_API_URL = 'https://api.digitalocean.com/v2';

interface OAuthResponse extends OAuthToken {
  token_type: string;
  expires_in: string;
  state: string;
}

const sizes: InstanceTypeMap = {
  [ComputeInstanceSize.Micro]: ['s-1vcpu-512mb-10gb', 4], // $4
  [ComputeInstanceSize.Small]: ['s-1vcpu-1gb', 6], // $6
  [ComputeInstanceSize.Medium]: ['s-1vcpu-2gb', 12], // $12
  [ComputeInstanceSize.Large]: ['s-4vcpu-8gb', 48], // $48
  [ComputeInstanceSize.XLarge]: ['s-8vcpu-16gb', 96], // $96
  [ComputeInstanceSize.XLargeAI]: ['c2-48vcpu-96gb-intel', 1466], // $1466
};

const images = {
  [ComputeInstanceImage.UbuntuLTS]: 'ubuntu-20-04-x64', // 20.04 for now
  [ComputeInstanceImage.Ubuntu2204]: 'ubuntu-22-04-x64',
  [ComputeInstanceImage.Ubuntu2004]: 'ubuntu-20-04-x64',
};

/**
 * Digital Ocean compute provider implementation.
 *
 * Authentication can be done in one of two ways:
 * - User provided Personal Access Token
 * - OAuth web flow
 *
 * The latter is a simpler flow as it does not require the user to know how to
 * generate a personal access token and we can (and should) revoke it once we
 * are finished using it to avoid potential token leakage scenarios.
 */
export class DigitalOcean implements ComputeProvider, OAuthSupport {
  static config: OAuthConfig & {
    SSHKeys?: number[];
  };

  /**
   * Access token to use for making API requests
   */
  #oAuthAccessToken?: OAuthToken;

  #oAuthCallbackUrl?: string;

  #oAuthState?: string;

  #oAuthHeaders?: HeadersInit;

  #client: FetchWrapper;

  constructor() {
    this.#client = new FetchWrapper({ baseURL: DO_API_URL });
  }

  getConfig() {
    const oauthToken = this.getOAuthToken();
    if (oauthToken === undefined) {
      return undefined;
    }

    const config: ComputeProviderConfig = {
      id: crypto.randomUUID(),
      providerID: ComputeProviderName.DigitalOcean,
      name: 'DigitalOcean',
      credentials: {
        accessToken: oauthToken.access_token,
        refreshToken: oauthToken.refresh_token,
      },
    };

    return config;
  }

  setConfig(config: ComputeProviderConfig) {
    if (config.providerID !== ComputeProviderName.DigitalOcean) {
      throw new Error(
        `DigitalOcean provider configured with wrong config type: ${config.providerID}`
      );
    }

    this.#oAuthAccessToken = {
      access_token: config.credentials.accessToken,
      refresh_token: config.credentials.refreshToken,
    };
  }

  isConfigured(): boolean {
    return !!this.#oAuthAccessToken && !!this.#oAuthAccessToken.access_token;
  }

  getInstanceType(size: ComputeInstanceSize): InstanceType {
    return sizes[size];
  }

  getInstanceImage(image: ComputeInstanceImage, region: string): string {
    return images[image];
  }

  /**
   * Create DigitalOcean Droplet and wait for an IPV4 address to be assigned
   * before returning.
   *
   * @param req
   * @returns {Instance}
   */
  async createInstance(req: CreateInstanceRequest): Promise<Instance> {
    const now = new Date();
    const createReq: ICreateDropletApiRequest = {
      name: req.name!,
      region: req.instanceRegion!,
      size: req.instanceType!,
      image: req.instanceImage!,
      user_data: req.userData,
      // TODO: make this configurable? leaving enabled for now as most BYOH users will likely
      // expect to be able to access their droplets. leaving it on does *NOT* give clovyr any
      // sort of access, only the owner of the DO account.
      // with_droplet_agent: false,
    };

    if (DigitalOcean.config.SSHKeys?.length) {
      createReq.ssh_keys = DigitalOcean.config.SSHKeys;
    }

    const res = await this.#client.post('/droplets', createReq, {
      headers: this.#getAuthHeaders(),
    });

    if (res instanceof Response) {
      const data = (await res.json()) as IDropletResponse;
      const instance = await this.#waitForNetwork(data.droplet.id.toString());
      instance.createstarted = now;
      instance.userdata = req.userData;
      return instance;
    }
    throw res as Error;
  }

  /**
   * Wait for an ipv4 address to be assigned to the droplet
   *
   * @param dropletId
   * @returns
   */
  async #waitForNetwork(dropletId: string): Promise<Instance> {
    /* eslint-disable no-await-in-loop */
    let instance: Instance;
    for (let tries = 30; tries > 0; tries--) {
      await sleep(5000);
      try {
        instance = await this.getInstance({ id: dropletId });
        if (instance.ipv4) {
          return instance;
        }
      } catch {
        // no-op, retry
      }
    }
    throw new Error('instance failed to initialize (no ipv4 assigned)');
    /* eslint-enable no-await-in-loop */
  }

  async destroyInstance(req: DestroyInstanceRequest): Promise<DestroyInstanceResponse> {
    await this.#client.delete(`/droplets/${req.id}`, undefined, {
      headers: this.#getAuthHeaders(),
    });
    return {};
  }

  async updateInstance(req: UpdateInstanceRequest): Promise<Instance> {
    throw new Error('not implemented');
  }

  // List of instances we have access to
  async listInstances(req: ListInstancesRequest): Promise<Instance[]> {
    throw new Error('not implemented');
  }

  // Fetch instance details, if authorized
  async getInstance(req: GetInstanceRequest): Promise<Instance> {
    const res = await this.#client.get(`/droplets/${req.id}`, {
      headers: this.#getAuthHeaders(),
    });
    if (res instanceof Response) {
      return this.#dropletToInstance(((await res.json()) as IDropletResponse).droplet);
    }
    throw res as Error;
  }

  #dropletToInstance(droplet: IDroplet): Instance {
    const instance: Instance = {
      fqdn: droplet.name,
      remoteinstanceid: droplet.id.toString(),
      // stub
      managedinstancetemplate: 4,
      id: 0,
      instanceprovider: 0,
      dnsprovider: 0,
    };
    // assign network address, if available
    if (droplet.networks && droplet.networks.v4 && droplet.networks.v4.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const net of droplet.networks.v4) {
        if (net && net.type === 'public') {
          instance.ipv4 = net.ip_address;
          return instance;
        }
      }
    }
    return instance;
  }

  // auth
  requiresAuth(): boolean {
    return true;
  }

  /**
   * Retrieve the oauth token
   *
   * @returns
   */
  getOAuthToken(): OAuthToken | undefined {
    return this.#oAuthAccessToken;
  }

  /**
   * Create the OAuth2 authorization URL.
   *
   * User should be directed to visit the returned URL and complete the OAuth
   * handshake with Digital Ocean.
   *
   * We use the implicit Oauth2 flow
   * https://docs.digitalocean.com/reference/api/oauth-api/#go_client-application-flow
   *
   * @param baseCallbackURI string base URL for callback (e.g., 'https://clovyr.app')
   * @param clientID
   * @returns string URL
   */
  createOAuthUrl(baseCallbackURI?: string, clientID?: string): string {
    baseCallbackURI ||= DigitalOcean.config.CallbackURI;
    clientID ||= DigitalOcean.config.ClientID;

    this.#oAuthState = createRandomKey();
    // TODO: should the entire URL be passed from client? ultimately this route
    // needs to be handled by the UI layer and the fragment passed back in.
    this.#oAuthCallbackUrl = `${baseCallbackURI}/oauth/callbacks/do`;

    const opts = {
      client_id: clientID,
      redirect_uri: this.#oAuthCallbackUrl,
      state: this.#oAuthState,
      response_type: 'token',
      scope: 'read write',
    };

    return `${DO_OAUTH_URL}/authorize${toQS(opts)}`;
  }

  /**
   * Provide OAuth token, either directly (personal access token) or as hash of
   * params retrieved from callback.
   *
   * @param params Hash of key/value pairs or the access token itself as a string
   * @returns
   */
  setOAuthToken(params: Params | string): OAuthToken {
    if (typeof params === 'string') {
      this.#oAuthAccessToken = { access_token: params };
      return this.#oAuthAccessToken;
    }
    if (!params || !params['access_token']) {
      // must have at least an access_token
      throw new Error('invalid token response');
    }
    const token = params as unknown as OAuthResponse;
    if (this.#oAuthState && token.state !== this.#oAuthState) {
      // validate state for the oauth flow
      throw new Error('invalid token response: state mismatch');
    }
    this.#oAuthAccessToken = { access_token: token.access_token };
    return this.#oAuthAccessToken;
  }

  /**
   * De-authorize stored token if no longer needed
   */
  async revokeOAuthToken(): Promise<void> {
    if (!(this.#oAuthAccessToken && this.#oAuthAccessToken.access_token)) {
      return;
    }
    await this.#client
      .post('/revoke', undefined, {
        headers: this.#getAuthHeaders(),
      })
      .then(() => {
        this.#oAuthAccessToken = undefined;
        this.#oAuthHeaders = undefined;
      });
  }

  #getAuthHeaders(): HeadersInit {
    if (!this.#oAuthHeaders) {
      this.#oAuthHeaders = Object.freeze({
        Authorization: `Bearer ${this.#oAuthAccessToken?.access_token}`,
        'Content-Type': 'application/json',
      });
    }
    return this.#oAuthHeaders;
  }
}
