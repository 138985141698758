import { type ComputeProviderConfig, ComputeProviderName, type Instance } from '../types';
import { sleep } from '../util/sleep';

import {
  ComputeInstanceImage,
  ComputeInstanceRegion,
  ComputeInstanceSize,
  type ComputeProvider,
  type CreateInstanceRequest,
  type DestroyInstanceRequest,
  type DestroyInstanceResponse,
  type GetInstanceRequest,
  type InstanceType,
  type InstanceTypeMap,
  type ListInstancesRequest,
  type UpdateInstanceRequest,
} from './index';

const sizes: InstanceTypeMap = {
  [ComputeInstanceSize.Micro]: ['t3.nano', 4], // ~$4
  [ComputeInstanceSize.Small]: ['t3.micro', 8], // ~$8
  [ComputeInstanceSize.Medium]: ['t3.small', 15], // ~$15
  [ComputeInstanceSize.Large]: ['t3a.large', 55], // ~$55
  [ComputeInstanceSize.XLarge]: ['t3a.xlarge', 110], // ~$110
  [ComputeInstanceSize.XLargeAI]: ['c7g.16xlarge', 1693], // ~$1693
};

const images = {
  'us-east-2': {
    [ComputeInstanceImage.UbuntuLTS]: 'ami-0a23d90349664c6ee', // 20.04 for now
    [ComputeInstanceImage.Ubuntu2204]: 'ami-097a2df4ac947655f', // Ubuntu Server 22.04 LTS (HVM), SSD Volume Type
    [ComputeInstanceImage.Ubuntu2004]: 'ami-0a23d90349664c6ee', //
  },
  // TODO: add rest of AMIs for each region
};

/**
 * AWS EC2 compute provider implementation.
 */
export class AWSEC2 implements ComputeProvider {
  #credentials?: AWSCredentials;

  getConfig() {
    if (this.#credentials === undefined) {
      return undefined;
    }

    const config: ComputeProviderConfig = {
      id: crypto.randomUUID(),
      providerID: ComputeProviderName.AWSEC2,
      name: 'Amazon Web Services',
      credentials: this.#credentials,
    };

    return config;
  }

  setConfig(config: ComputeProviderConfig): void {
    if (config.providerID !== ComputeProviderName.AWSEC2) {
      throw new Error(`AWS EC2 provider configured with wrong config type: ${config.providerID}`);
    }

    this.#credentials = config.credentials;
  }

  isConfigured(): boolean {
    return (
      !!this.#credentials && !!this.#credentials.accessKeyID && !!this.#credentials.secretAccessKey
    );
  }

  getInstanceType(size: ComputeInstanceSize): InstanceType {
    return sizes[size];
  }

  getInstanceImage(image: ComputeInstanceImage, region: string): string {
    return images?.[region]?.[image];
  }

  async createInstance(req: CreateInstanceRequest): Promise<Instance> {
    if (!this.#credentials) {
      throw new Error('cannot create instance when no credentials are set');
    }
    if (!req.name) {
      throw new Error('create instance request does not contain a name');
    }

    const region = req.instanceRegion ?? 'us-east-2';
    const securityGroupID = await createSecurityGroup(this.#credentials, region, req);
    await attachSecurityGroupRule(this.#credentials, region, securityGroupID);
    const instanceID = await runInstance(this.#credentials, req, securityGroupID);

    // console.log('waiting for instance to get a public IP address');
    const publicIP = await getInstancePublicIP(this.#credentials, region, instanceID);

    return {
      fqdn: req.name,
      remoteinstanceid: instanceID,
      ipv4: publicIP,

      // stub
      managedinstancetemplate: 4,
      id: 0,
      instanceprovider: 0,
      dnsprovider: 0,
      instance_region: region,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async listInstances(req: ListInstancesRequest): Promise<Instance[]> {
    throw new Error('unimplemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getInstance(req: GetInstanceRequest): Promise<Instance> {
    throw new Error('unimplemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async updateInstance(req: UpdateInstanceRequest): Promise<Instance> {
    throw new Error('unimplemented');
  }

  async destroyInstance(req: DestroyInstanceRequest): Promise<DestroyInstanceResponse> {
    if (!this.#credentials) {
      throw new Error('cannot destroy instance when no credentials are set');
    }
    const credentials = this.#credentials;
    const region = req.region ?? 'us-east-2';

    // Security groups attached to an instance cannot be deleted immediately
    // after the instance is terminated. There is a delay while AWS sorts out
    // details with ENIs. Instead of waiting for this to happen, we can instead
    // swap out the security group on the instance with the default security
    // group, which is guaranteed to exist because it cannot be deleted.
    const securityGroupIDs = await getInstanceSecurityGroups(credentials, region, req.id);
    const defaultSecurityGroupID = await getDefaultSecurityGroupID(credentials, region);
    await detachSecurityGroups(credentials, region, req.id, defaultSecurityGroupID);

    await terminateInstance(credentials, region, req.id);
    await Promise.all(securityGroupIDs.map((id) => deleteSecurityGroup(credentials, region, id)));
    return {};
  }

  setCredentials(accessKeyID: string, secretAccessKey: string): void {
    this.#credentials = { accessKeyID, secretAccessKey };
  }
}

export interface AWSCredentials {
  accessKeyID: string;
  secretAccessKey: string;
}

interface AWSRequest {
  endpoint: string;
  params: AWSRequestParams;
}

interface AWSRequestParams {
  Action: string;
  Version: string;
  [key: string]: string;
}

async function getInstancePublicIP(
  credentials: AWSCredentials,
  region: string,
  instanceID: string
): Promise<string> {
  const retryIntervalSecs = 5;
  const timeOutSecs = 60 * 20;
  let elapsedSecs = 0;
  /* eslint-disable no-await-in-loop */
  while (elapsedSecs < timeOutSecs) {
    const response = await signAndSendRequest(credentials, {
      endpoint: `ec2.${region}.amazonaws.com`,
      params: {
        Action: 'DescribeInstances',
        Version: '2016-11-15',
        'InstanceId.1': instanceID,
      },
    });

    const responseXML = await response.text();
    const doc = new DOMParser().parseFromString(responseXML, 'text/xml');
    const publicIP = doc.querySelector('networkInterfaceSet publicIp')?.textContent;

    if (publicIP) {
      return publicIP;
    }

    await sleep(retryIntervalSecs * 1000);
    elapsedSecs += retryIntervalSecs;
    // console.log('not yet...  retrying now');
  }
  /* eslint-enable no-await-in-loop */

  throw new Error('Timed out waiting for public IP address');
}

async function runInstance(
  credentials: AWSCredentials,
  req: CreateInstanceRequest,
  securityGroupID: string
): Promise<string> {
  if (!req.instanceImage) {
    throw new Error('no AMI specified');
  }

  const region = req.instanceRegion ?? 'us-east-2';
  const awsRequest: AWSRequest = {
    endpoint: `ec2.${region}.amazonaws.com`,
    params: {
      Action: 'RunInstances',
      Version: '2016-11-15',
      ImageId: req.instanceImage,
      MinCount: '1',
      MaxCount: '1',
      InstanceType: req.instanceType ?? 't2.micro',
      'NetworkInterface.1.DeviceIndex': '0',
      'NetworkInterface.1.AssociatePublicIpAddress': 'true',
      'NetworkInterface.1.SecurityGroupId.1': securityGroupID,
    },
  };

  if (req.userData) {
    awsRequest.params['UserData'] = window.btoa(req.userData);
  }

  if (req.name) {
    Object.assign(awsRequest.params, {
      'TagSpecification.1.ResourceType': 'instance',
      'TagSpecification.1.Tag.1.Key': 'Name',
      'TagSpecification.1.Tag.1.Value': req.name,
    });
  }

  const response = await signAndSendRequest(credentials, awsRequest);
  const responseXML = await response.text();

  const parser = new DOMParser();
  const doc = parser.parseFromString(responseXML, 'text/xml');
  const instanceID = doc.querySelector('instanceId')?.textContent;
  if (!instanceID) {
    throw new Error('no instance ID found in RunInstances response');
  }
  return instanceID;
}

async function terminateInstance(
  credentials: AWSCredentials,
  region: string,
  instanceID: string
): Promise<void> {
  const awsRequest: AWSRequest = {
    endpoint: `ec2.${region}.amazonaws.com`,
    params: {
      Action: 'TerminateInstances',
      Version: '2016-11-15',
      'InstanceId.1': instanceID,
    },
  };

  await signAndSendRequest(credentials, awsRequest);
}

async function getDefaultSecurityGroupID(
  credentials: AWSCredentials,
  region: string
): Promise<string> {
  const awsRequest: AWSRequest = {
    endpoint: `ec2.${region}.amazonaws.com`,
    params: {
      Action: 'DescribeSecurityGroups',
      Version: '2016-11-15',
      'GroupName.1': 'default',
    },
  };

  const response = await signAndSendRequest(credentials, awsRequest);
  const responseXML = await response.text();
  const doc = new DOMParser().parseFromString(responseXML, 'text/xml');
  const securityGroupID = doc.querySelector('securityGroupInfo groupId')?.textContent;
  if (!securityGroupID) {
    throw new Error('no default security group found');
  }
  return securityGroupID;
}

async function detachSecurityGroups(
  credentials: AWSCredentials,
  region: string,
  instanceID: string,
  defaultGroupID: string
): Promise<void> {
  const awsRequest: AWSRequest = {
    endpoint: `ec2.${region}.amazonaws.com`,
    params: {
      Action: 'ModifyInstanceAttribute',
      Version: '2016-11-15',
      'GroupId.1': defaultGroupID,
      InstanceId: instanceID,
    },
  };

  await signAndSendRequest(credentials, awsRequest);
}

async function createSecurityGroup(
  credentials: AWSCredentials,
  region: string,
  req: CreateInstanceRequest
): Promise<string> {
  const response = await signAndSendRequest(credentials, {
    endpoint: `ec2.${region}.amazonaws.com`,
    params: {
      Action: 'CreateSecurityGroup',
      Version: '2016-11-15',
      // Note: this was mispelled as `clovry.instance` for a few months.
      GroupName: `clovyr.instance/${req.name}`,
      GroupDescription: `Allows HTTP(S) traffic for Clovyr instance ${req.name}`,
    },
  });
  const responseXML = await response.text();
  const doc = new DOMParser().parseFromString(responseXML, 'text/xml');
  const groupID = doc.querySelector('groupId')?.textContent;
  if (!groupID) {
    throw new Error('no security group ID in response');
  }
  return groupID;
}

async function attachSecurityGroupRule(
  credentials: AWSCredentials,
  region: string,
  securityGroupID: string
): Promise<void> {
  await signAndSendRequest(credentials, {
    endpoint: `ec2.${region}.amazonaws.com`,
    params: {
      Action: 'AuthorizeSecurityGroupIngress',
      Version: '2016-11-15',
      GroupId: securityGroupID,
      'IpPermissions.1.IpProtocol': 'tcp',
      'IpPermissions.1.FromPort': '443',
      'IpPermissions.1.ToPort': '443',
      'IpPermissions.1.IpRanges.1.CidrIp': '0.0.0.0/0',
      // Plain HTTP needed for Let's Encrypt challange.
      'IpPermissions.2.IpProtocol': 'tcp',
      'IpPermissions.2.FromPort': '80',
      'IpPermissions.2.ToPort': '80',
      'IpPermissions.2.IpRanges.1.CidrIp': '0.0.0.0/0',
    },
  });
}

async function deleteSecurityGroup(
  credentials: AWSCredentials,
  region: string,
  securityGroupID: string
): Promise<void> {
  const awsRequest: AWSRequest = {
    endpoint: `ec2.${region}.amazonaws.com`,
    params: {
      Action: 'DeleteSecurityGroup',
      Version: '2016-11-15',
      GroupId: securityGroupID,
    },
  };

  await signAndSendRequest(credentials, awsRequest);
}

async function getInstanceSecurityGroups(
  credentials: AWSCredentials,
  region: string,
  instanceID: string
): Promise<string[]> {
  const awsRequest: AWSRequest = {
    endpoint: `ec2.${region}.amazonaws.com`,
    params: {
      Action: 'DescribeInstances',
      Version: '2016-11-15',
      'InstanceId.1': instanceID,
    },
  };

  const response = await signAndSendRequest(credentials, awsRequest);
  const responseXML = await response.text();
  const parser = new DOMParser();
  const doc = parser.parseFromString(responseXML, 'text/xml');

  const securityGroupIDElems = doc.querySelectorAll('instancesSet > item > groupSet groupId');

  const securityGroupIDs: string[] = [];
  for (let i = 0; i < securityGroupIDElems.length; i++) {
    const id = securityGroupIDElems[i].textContent;
    if (!id) {
      throw new Error('security group has no ID');
    }
    securityGroupIDs.push(id);
  }
  return securityGroupIDs;
}

async function signAndSendRequest(credentials: AWSCredentials, request: AWSRequest) {
  const body = new URLSearchParams(request.params).toString();
  const bodyHash = arrayBufferToHex(await sha256(body));

  const endpointLabels = request.endpoint.split('.');
  let service: string;
  let region: string;
  if (endpointLabels.length === 4) {
    [service, region] = endpointLabels;
  } else if (endpointLabels.length === 3) {
    [service] = endpointLabels;
    region = 'us-east-1'; // global APIs like STS want requets scoped to us-east-1
  } else {
    throw new Error(
      `endpoint must be in format <service>.amazonaws.com or <service>.<region>.amazonaws.com: ${request.endpoint}`
    );
  }

  const headers = {
    Host: request.endpoint,
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
  };

  const dateTime = amazonDate();
  headers['X-Amz-Date'] = dateTime;

  const signedHeaderNames: string[] = [];
  const headersToSign: string[] = [];
  Object.keys(headers).forEach((name) => {
    const lowerName = name.toLowerCase();
    headersToSign.push(`${lowerName}:${headers[name]}`);
    signedHeaderNames.push(lowerName);
  });
  headersToSign.sort();
  signedHeaderNames.sort();

  const canonicalHeaders = `${headersToSign.join('\n')}\n`;
  const signedHeaders = signedHeaderNames.join(';');

  const canonicalRequest = [
    'POST',
    '/', // no path needed when using POST requests
    '', // no query string
    canonicalHeaders,
    signedHeaders,
    bodyHash,
  ].join('\n');

  const algorithm = 'AWS4-HMAC-SHA256';

  const hashedCanonicalRequest = arrayBufferToHex(await sha256(canonicalRequest));

  const date = dateTime.slice(0, 8); // YYYYMMDD
  const credentialScope = `${date}/${region}/${service}/aws4_request`;
  const stringToSign = [algorithm, dateTime, credentialScope, hashedCanonicalRequest].join('\n');

  const kSecret = credentials.secretAccessKey;
  const kDate = await hmacSha256(`AWS4${kSecret}`, date);
  const kRegion = await hmacSha256(kDate, region);
  const kService = await hmacSha256(kRegion, service);
  const kSigning = await hmacSha256(kService, 'aws4_request');

  const signature = arrayBufferToHex(await hmacSha256(kSigning, stringToSign));

  headers['Authorization'] = [
    `AWS4-HMAC-SHA256 Credential=${credentials.accessKeyID}/${credentialScope}`,
    `SignedHeaders=${signedHeaders}`,
    `Signature=${signature}`,
  ].join(', ');

  const response = await fetch(`https://${request.endpoint}`, {
    method: 'POST',
    body,
    headers,
  });

  if (!response.ok) {
    const text = await response.text();
    const doc = new DOMParser().parseFromString(text, 'text/xml');

    // console.log(text);
    const errors: AWSError[] = [];
    const errorNodes = doc.querySelectorAll('Response > Errors > Error');
    for (let i = 0; i < errorNodes.length; i++) {
      const err = errorNodes[i];
      errors.push({
        code: err.querySelector('Code')?.textContent || 'unknown error code',
        message: err.querySelector('Message')?.textContent || 'unknown error',
      });
    }
    throw new Error(JSON.stringify(errors));
  }

  return response;
}

interface AWSError {
  code: string;
  message: string;
}

function arrayBufferToHex(buffer: ArrayBuffer) {
  return [...new Uint8Array(buffer)].map((b) => b.toString(16).padStart(2, '0')).join('');
}

async function hmacSha256(keyInput: string | BufferSource, data: string): Promise<ArrayBuffer> {
  let keyBytes: BufferSource;
  if (typeof keyInput === 'string') {
    keyBytes = new TextEncoder().encode(keyInput);
  } else {
    keyBytes = keyInput;
  }

  const key = await window.crypto.subtle.importKey(
    'raw',
    keyBytes,
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign']
  );

  const encodedData = new TextEncoder().encode(data);
  return window.crypto.subtle.sign('HMAC', key, encodedData);
}

async function sha256(data: string): Promise<ArrayBuffer> {
  const encodedData = new TextEncoder().encode(data);
  return window.crypto.subtle.digest('SHA-256', encodedData);
}

function amazonDate() {
  return new Date()
    .toISOString()
    .replace(/[:-]/g, '')
    .replace(/\.[^Z]*/, '');
}
